var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c("v-autocomplete", {
        staticStyle: { "margin-top": "26px" },
        attrs: {
          value: _vm.selectedParties,
          items: _vm.parties,
          "item-text": "name",
          "item-value": "id",
          label: "Parties",
          loading: _vm.loadingParties,
          "search-input": _vm.partySearch,
          autofocus: !_vm.isNonAdminUser && _vm.selectedParties.length === 0,
          "background-color": "input",
          "prepend-inner-icon": _vm.isNonAdminUser ? null : "mdi-magnify",
          placeholder: _vm.isNonAdminUser ? null : "Start typing to search",
          "hide-no-data": "",
          multiple: "",
          "cache-items": "",
          "return-object": "",
          "small-chips": "",
          dense: "",
          outlined: "",
        },
        on: {
          "update:searchInput": function ($event) {
            _vm.partySearch = $event
          },
          "update:search-input": function ($event) {
            _vm.partySearch = $event
          },
          change: function ($event) {
            return _vm.$emit("update:selectedParties", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function (ref) {
              var attrs = ref.attrs
              var selected = ref.selected
              var select = ref.select
              var index = ref.index
              var item = ref.item
              return [
                _c(
                  "v-chip",
                  _vm._b(
                    {
                      attrs: { "input-value": selected, close: "", small: "" },
                      on: {
                        click: select,
                        "click:close": function ($event) {
                          return _vm.$emit("remove", index)
                        },
                      },
                    },
                    "v-chip",
                    attrs,
                    false
                  ),
                  [
                    _c("span", { staticClass: "text-truncate" }, [
                      _vm._v(" " + _vm._s("" + item.name) + " "),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "item",
            fn: function (ref) {
              var item = ref.item
              var attrs = ref.attrs
              var on = ref.on
              return [
                _c(
                  "v-list-item",
                  _vm._g(
                    _vm._b(
                      { attrs: { color: "primary" } },
                      "v-list-item",
                      attrs,
                      false
                    ),
                    on
                  ),
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [
                          _vm._v(" " + _vm._s(item.name) + " "),
                        ]),
                        _c("v-list-item-subtitle", [
                          _vm._v(" " + _vm._s(item.party_type_name) + " "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }