var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _vm._l(_vm.doc_tags, function (tag) {
        return [
          _c(
            "v-col",
            { key: tag.id, attrs: { cols: _vm.$props.cols } },
            [
              _c("v-autocomplete", {
                attrs: {
                  label: tag.name,
                  items:
                    tag.id.toLowerCase() === "category"
                      ? _vm.userCategories
                      : tag.children,
                  "prepend-inner-icon": "mdi-filter-outline",
                  "item-text": "name",
                  "item-value": "id",
                  "hide-details": "auto",
                  "background-color": "input",
                  dense: "",
                  clearable: "",
                  outlined: "",
                },
                model: {
                  value: _vm.value["" + tag.id.toLowerCase()],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "" + tag.id.toLowerCase(), $$v)
                  },
                  expression: "value[`${tag.id.toLowerCase()}`]",
                },
              }),
            ],
            1
          ),
          _vm.filterChildTags(tag.id).length > 0
            ? _vm._l(_vm.filterChildTags(tag.id), function (childTag) {
                return _c(
                  "v-col",
                  { key: childTag.id, attrs: { cols: _vm.$props.cols } },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        label: childTag.name,
                        items: childTag.children,
                        "prepend-inner-icon": "mdi-filter-outline",
                        "item-text": "name",
                        "item-value": "id",
                        "hide-details": "auto",
                        "background-color": "input",
                        dense: "",
                        outlined: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.value[childTag.id.toLowerCase()],
                        callback: function ($$v) {
                          _vm.$set(_vm.value, childTag.id.toLowerCase(), $$v)
                        },
                        expression: "value[childTag.id.toLowerCase()]",
                      },
                    }),
                  ],
                  1
                )
              })
            : _vm._e(),
        ]
      }),
      _c(
        "v-col",
        { attrs: { cols: _vm.$props.cols } },
        [
          _c("v-autocomplete", {
            attrs: {
              label: "Status",
              "prepend-inner-icon": "mdi-filter-outline",
              items: _vm.statuses,
              "item-text": "name",
              "item-value": "id",
              dense: "",
              outlined: "",
              "hide-details": "auto",
              "background-color": "input",
              clearable: "",
            },
            model: {
              value: _vm.value.status,
              callback: function ($$v) {
                _vm.$set(_vm.value, "status", $$v)
              },
              expression: "value.status",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }